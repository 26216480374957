<template>
<li
    v-if="canViewVerticalNavMenuGroup(item) && isEnabled(item) && storeIsEnable(item) && isZatca(item) && isTempFinancial(item) && deservedFeeAppearanceHandle(item)"
    class="nav-item has-sub"
    :class="{
      'open': isOpen,
      'disabled': item.disabled,
      'sidebar-group-active': isActive,
    }"
  >
    <b-link
      class="d-flex align-items-center"
      @click="() => updateGroupOpen(!isOpen)"
    >
      <feather-icon v-if="item.icon" :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
    <b-collapse
      v-model="isOpen"
      class="menu-content"
      tag="ul"
    >
    <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
      <component
        :is="resolveNavItemComponent(child)"
        v-for="child in item.children"
        :key="child.header || child.title"
        ref="groupChild"
        :item="child"
      />
    </b-collapse>
  </li>
</template>

<script>
import { BLink, BBadge, BCollapse } from 'bootstrap-vue'
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useUtils as useAclUtils } from '@core/libs/acl'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composition Function
import useVerticalNavMenuGroup from './useVerticalNavMenuGroup'
import mixinVerticalNavMenuGroup from './mixinVerticalNavMenuGroup'

export default {
  name: 'VerticalNavMenuGroup',
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    BLink,
    BBadge,
    BCollapse,
  },
  data() {
    return {
      isEnabledAccounts: true,
      isEnabledStore: true,
      isForZatca: true,
      isForTempFinancial: true,
      show: false,
      isForDeservedFee: true,
      deservedFeePermissions: [
        'viewDeservedFees',
        'addDeservedFees'
      ],
      deservedFeeActivityPermissions: [
        'addSalesTransactions',
        'addReturnSalesTransactions',
        'addStudentInvoices',
        'addDiscountCollection'
      ]
    };
  },
  mixins: [mixinVerticalNavMenuGroup],
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const {
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,
    } = useVerticalNavMenuGroup(props.item, props.storItem)

    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuGroup } = useAclUtils()

    return {
      resolveNavItemComponent,
      isOpen,
      isActive,
      updateGroupOpen,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuGroup,

      // i18n
      t,
    }
  },
  mounted() {
          if (localStorage.accountsEnabled) {
      this.isEnabledAccounts = JSON.parse(localStorage.getItem('accountsEnabled'))
     }
  },
  methods: {
    deservedFeeAppearanceHandle(item) {
      const showDerservedFee = this.currentBranch.isTaxCollectionActivated && this.deservedFeePermissions.includes(item.permission);
      const showInvoicesAndReturns = !this.currentBranch.isStudentBalanceBasedOnFeesActivated && this.deservedFeeActivityPermissions.includes(item.permission);
      if (item.isForDeservedFee && (showDerservedFee || showInvoicesAndReturns)) {
        this.isForDeservedFee = true
      } else {
        this.isForDeservedFee = false
      }
      if (item.isForDeservedFee && (item.isForDeservedFee === this.isForDeservedFee)) {
        return true
      }
      if (item.isForDeservedFee === undefined) {
        return true
      }
    },
    isEnabled(item) {
       if (item.isEnabled && (item.isEnabled === this.isEnabledAccounts)) {
        return true
      }
      if (item.isEnabled === undefined) {
        return true
      }
    },
    isZatca(item) {
      if (item.isForZatca && this.company.enableZatcaIntegration && this.profile.isAdmin) {
      this.isForZatca = true
      } else {
        this.isForZatca = false
      }
      if (item.isForZatca && (item.isForZatca === this.isForZatca)) {
        return true
      }
      if (item.isForZatca === undefined) {
        return true
      }
    },
    isTempFinancial(item) {
      if (item.isForTempFinancial && this.company.enableElectronicPayment) {
      this.isForTempFinancial = true
      } else {
        this.isForTempFinancial = false
      }
      if (item.isForTempFinancial && (item.isForTempFinancial === this.isForTempFinancial)) {
        return true
      }
      if (item.isForTempFinancial === undefined) {
        return true
      }
    },
    storeIsEnable(storItem) {
      if (storItem.isEnabledStore && (storItem.isEnabledStore === this.isEnabledStore) && this.profile.storesEnabled) {
        this.isEnabledStore = true
      } else {
        this.isEnabledStore = false
      }
      if (storItem.isEnabledStore && (storItem.isEnabledStore === this.isEnabledStore)) {
        return true
      }
      if (storItem.isEnabledStore === undefined) {
        return true
      }
    },
  }
}
</script>

<style>

</style>
